import React, { FC, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  faUser,
  faLightbulb,
  faStickyNote,
  faChartBar,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { BoldAngleIcon } from "../../../Icons";

import "./navigation-bar.scss";
import "../../../scss/main.scss";
import "./navigation-bar.scss";

export const MENU_ITEMS = [
  { id: 0, label: "Manage Users", icon: faUser, path: "/user-management" },
  {
    id: 1,
    label: "Manage Values",
    icon: faLightbulb,
    path: "/values-management",
  },
  {
    id: 2,
    label: "Manage Coupon",
    icon: faStickyNote,
    path: "/coupon-management",
  },
  { id: 3, label: "Dashboard", icon: faChartBar, path: "/dashboard" },
];

export const NavigationBar: FC = () => {
  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  const { pathname } = location;

  return (
    <nav className={`nav-bar ${isExpanded ? "extendable" : ""}`}>
      <ul>
        <li onClick={() => setIsExpanded(!isExpanded)}>
          <BoldAngleIcon />
        </li>

        {MENU_ITEMS.map((item) => {
          return (
            <NavigationElement
              key={item.id}
              icon={item.icon}
              show={isExpanded}
              activeLink={item.path === pathname}
              name={item.label}
              path={item.path}
            />
          );
        })}
      </ul>
    </nav>
  );
};

interface Props {
  activeLink: boolean;
  icon: IconDefinition;
  show: boolean;
  name: string;
  path: string;
}

const NavigationElement: React.FC<Props> = ({
  activeLink,
  icon,
  show,
  name,
  path,
}) => {
  return (
    <li className={activeLink ? "active" : ""}>
      <Link to={path} className="link-hide-underline">
        <FontAwesomeIcon icon={icon} />
        <span className={`${show ? "show" : ""}`}>{name}</span>
      </Link>
    </li>
  );
};
